import React from "react"
import { useTranslation } from "react-i18next"
import ErrorMessage from "./ErrorMessage"
import { I18N_NS } from "../../utils/constants"
import ErrorImg from "../../images/error@3x.png"
import NoResultsImg from "../../images/no-results@3x.png"
import { FetchError } from "../../api/flights"

export interface Props {
  error: FetchError | null
}

const ErrorMessageWrapper: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation(I18N_NS)

  if (error && error.statusCode !== 404) {
    const res = error.message.match(/([A-Z_]+)\s{{(.+)}}/)
    const completeMessageText = res
      ? t("FlightsResultsComponent.apiErrors.".concat(res[1]), { param: res[2] })
      : t("FlightsResultsComponent.messages.no_results")

    return (
      <ErrorMessage
        title={t("FlightsResultsComponent.errors.something_went_wrong")}
        message={t("FlightsResultsComponent.errors.retry_search", {
          errorMessage: completeMessageText
        })}
        imageSrc={ErrorImg}
      />
    )
  }

  return (
    <ErrorMessage
      title={t("FlightsResultsComponent.messages.no_results")}
      message={t("FlightsResultsComponent.messages.no_more_seats_or_flights")}
      imageSrc={NoResultsImg}
    />
  )
}

export default ErrorMessageWrapper
