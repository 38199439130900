import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  resultMessage: (theme: Theme) =>
    css({
      padding: "24px",
      display: "flex",
      flexFlow: "row nowrap",
      backgroundColor: "rgba(70, 128, 233, 0.12)",
      color: theme.colors.text_white,
      borderRadius: "4px"
    }),
  resultMessageError: css({
    backgroundColor: "rgba(208, 2, 27, 0.1)"
  }),
  resultMessageImg: css({
    height: "74px"
  })
}
