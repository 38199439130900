import React from "react"
import styles from "./ErrorMessage.styles"
import { useTheme } from "@basset-la/themed-components"
import Text from "@basset-la/components-commons/dist/components/Text"

export interface Props {
  title: string
  message: string
  imageSrc: string
}

const ErrorMessage: React.FC<Props> = ({ title, message, imageSrc }) => {
  const theme = useTheme()

  return (
    <div className={`${styles.resultMessage(theme)} ${styles.resultMessageError}`}>
      <div>
        <img className={styles.resultMessageImg} src={imageSrc} alt={title} />
      </div>
      <div>
        <Text size={16} component="p" variant="bold">
          {title}
        </Text>
        <Text size={14} component="p">
          {message}
        </Text>
      </div>
    </div>
  )
}

export default ErrorMessage
