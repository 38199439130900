import React from "react"
import { useTheme } from "@basset-la/themed-components"
import ClusterWeb from "@basset-la/components-flights/dist/components/ClusterWeb"
import styles from "./ClusterList.styles"
import { AdvertisingData } from "@basset-la/components-flights"
import { Cluster as ClusterModel } from "@basset-la/components-flights/dist/model"
import InfiniteScroll from "./InfiniteScroll"

interface Props {
  dataSource: ClusterModel[]
  selectedBrands: number[]
  advertising?: AdvertisingData
  isLoading: boolean
  onPaginatedSearch: () => void
  onSelectItinerary: (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => void
  onOpenBrandSelectionDialog: (c: ClusterModel, idx: number, selectedOptions: number[], selectedBrand: number) => void
}

const ClusterList: React.FC<Props> = ({
  dataSource,
  selectedBrands,
  isLoading,
  advertising,
  onSelectItinerary,
  onPaginatedSearch,
  onOpenBrandSelectionDialog
}) => {
  const theme = useTheme()

  const handleOnSelectItinerary = (clusterIndex: number) => (selectedOptions: number[], selectedBrand: number) => {
    onSelectItinerary(clusterIndex, selectedOptions, selectedBrand)
  }

  const handleOpenBrandSelectionDialog = (cluster: ClusterModel, idx: number) => (
    selectedOptions: number[],
    selectedBrand: number
  ) => {
    onOpenBrandSelectionDialog(cluster, idx, selectedOptions, selectedBrand)
  }

  const handlePaginatedSearch = () => {
    onPaginatedSearch()
  }

  return (
    <InfiniteScroll isLoading={isLoading} onPaginatedSearch={handlePaginatedSearch}>
      {dataSource.map((cluster: ClusterModel, i) => {
        return (
          <div id={`fligth-result-${i}`} key={i} className={styles.cluster(theme)}>
            <ClusterWeb
              key={i}
              cluster={cluster}
              selectedBrand={selectedBrands[i]}
              advertising={advertising}
              onSelectItinerary={handleOnSelectItinerary(i)}
              onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog(cluster, i)}
            />
          </div>
        )
      })}
    </InfiniteScroll>
  )
}

export default ClusterList
