import React from "react"
import { createBrowserHistory } from "redux-first-routing"
import { Router } from "react-router-dom"
import { Switch, Route } from "react-router-dom"
import Home from "./Home/Home"
import Results from "./Results/Results"

const history = createBrowserHistory()

const Routes: React.FC = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path="/flights/results" component={Results} />
        <Route path="/flights" component={Home} />
      </Switch>
    </Router>
  )
}

export default Routes
