import moment from "moment"
import QueryString from "qs"
import _ from "underscore"

import { DEFAULT_DEPARTURE_TIME } from "@basset-la/components-flights/dist/components/FlightsFilters"
import {
  Children,
  FlightClass,
  Leg,
  SearchboxParams,
  Stops
} from "@basset-la/components-flights/dist/components/Searchbox/types"
import { FlightClusterFilters } from "@basset-la/components-flights/dist/model"
import { FlightType, OrderByOptions } from "@basset-la/components-flights/dist/model/types"

import { CLUSTERS_PER_PAGE, DATE_FORMAT, NO_VALUE, ROUND_TRIP } from "./constants"
import { FlightClusterFiltersMatrix, FlightPagination } from "./types"

const minutesToDuration = (minutes: number) => {
  let hh: any = Math.floor(minutes / 60)
  let mm: any = minutes % 60
  hh = hh < 10 ? `0${hh}` : hh
  mm = mm < 10 ? `0${mm}` : mm
  return `${hh}:${mm}`
}

const mapQueryStringToState = (qString: string) => {
  const qsArray: Array<any> = Array.from(new URLSearchParams(qString).entries())
  return qsArray.reduce((mapQs, qs) => ({ ...mapQs, [qs[0]]: qs[1] }), {})
}

const filtersToApiQueryString = (filters: FlightClusterFilters) => {
  return {
    ...(filters.stops && filters.stops.size > 0 ? { stops: [...filters.stops].join(",") } : {}),
    ...(filters.airlines && filters.airlines.size > 0 ? { airlines: [...filters.airlines].join(",") } : {}),
    ...(filters.sourceType && filters.sourceType.size > 0 ? { source_type: [...filters.sourceType].join(",") } : {}),
    ...(filters.outboundDepartureAirports && filters.outboundDepartureAirports.size > 0
      ? { outbound_departure_airports: [...filters.outboundDepartureAirports].join(",") }
      : {}),
    ...(filters.outboundArrivalAirports && filters.outboundArrivalAirports.size > 0
      ? { outbound_arrival_airports: [...filters.outboundArrivalAirports].join(",") }
      : {}),
    ...(filters.inboundDepartureAirports && filters.inboundDepartureAirports.size > 0
      ? { inbound_departure_airports: [...filters.inboundDepartureAirports].join(",") }
      : {}),
    ...(filters.inboundArrivalAirports && filters.inboundArrivalAirports.size > 0
      ? { inbound_arrival_airports: [...filters.inboundArrivalAirports].join(",") }
      : {}),
    ...(filters.outboundDepartureTime &&
    filters.outboundDepartureTime.length > 0 &&
    !_.isEqual(filters.outboundDepartureTime, DEFAULT_DEPARTURE_TIME)
      ? {
          outbound_departure_time: [
            minutesToDuration(filters.outboundDepartureTime[0]),
            minutesToDuration(filters.outboundDepartureTime[1] - 1)
          ].join(",")
        }
      : {}),
    ...(filters.inboundDepartureTime &&
    filters.inboundDepartureTime.length > 0 &&
    !_.isEqual(filters.inboundDepartureTime, DEFAULT_DEPARTURE_TIME)
      ? {
          inbound_departure_time: [
            minutesToDuration(filters.inboundDepartureTime[0]),
            minutesToDuration(filters.inboundDepartureTime[1] - 1)
          ].join(",")
        }
      : {})
  }
}

export const mapFlightsParamsToFlightsApi = (
  searchParams: SearchboxParams,
  filters: FlightClusterFilters,
  pagination: FlightPagination,
  orderBy: OrderByOptions
) => {
  const obj = {
    dates: searchParams.legs
      .map(leg =>
        leg.to
          ? leg.from
            ? [leg.from.format("YYYY-MM-DD"), leg.to.format("YYYY-MM-DD")]
            : leg.from
          : leg.from
          ? leg.from.format("YYYY-MM-DD")
          : leg.from
      )
      .join(","),
    from:
      searchParams.flightType === ROUND_TRIP
        ? (searchParams.legs[0].origin?.iata_code! || "")
            .concat(",")
            .concat(searchParams.legs[0].destination?.iata_code! || "")
        : searchParams.legs.map(leg => leg.origin!.iata_code).join(","),
    to:
      searchParams.flightType === ROUND_TRIP
        ? (searchParams.legs[0].destination?.iata_code! || "")
            .concat(",")
            .concat(searchParams.legs[0].origin?.iata_code! || "")
        : searchParams.legs.map(leg => leg.destination!.iata_code).join(","),
    adults: searchParams.passengers.adults ? searchParams.passengers.adults : 0,
    ...(searchParams.passengers.children
      ? {
          children: searchParams.passengers.children.reduce(
            (init, child) => init + (child === "CHILDREN" || child === "INFANT_ON_SEAT" ? 1 : 0),
            0
          )
        }
      : {}),
    ...(searchParams.passengers.children
      ? {
          infants: searchParams.passengers.children.reduce(
            (init, child) => init + (child === "INFANT_IN_ARMS" ? 1 : 0),
            0
          )
        }
      : {}),
    ...(searchParams.flightClass && searchParams.flightClass !== NO_VALUE ? { cabin: searchParams.flightClass } : {}),
    ...(searchParams.includeBaggage ? { baggage_type: searchParams.includeBaggage } : {}),
    ...(searchParams.passengers.disableds ? { disabled: searchParams.passengers.disableds } : {}),
    ...(searchParams.passengers.seniors ? { seniors: searchParams.passengers.seniors } : {}),
    currency: searchParams.currency || "",
    ...filtersToApiQueryString(filters),
    offset: pagination.offset,
    limit: pagination.limit,
    order_by: orderBy
  }
  obj.stops = obj.stops || searchParams.stops || NO_VALUE
  if (!obj.airlines && searchParams.airlines && searchParams.airlines!.length > 0) {
    obj.airlines = searchParams.airlines!.join(",")
  }
  return obj
}

export const mapFlightsParamsToUrl = (
  searchParams: SearchboxParams,
  orderBy: OrderByOptions,
  filters: FlightClusterFilters | null = null,
  pagination: FlightPagination | null = null
) => {
  const urlParams = {
    dates: searchParams.legs
      .map(leg =>
        leg.to
          ? leg.from
            ? [leg.from.format("YYYY-MM-DD"), leg.to.format("YYYY-MM-DD")]
            : leg.from
          : leg.from
          ? leg.from.format("YYYY-MM-DD")
          : leg.from
      )
      .join(searchParams.flightType === "MULTIPLE_DESTINATIONS" ? "|" : ","),
    from: searchParams.legs.map(leg => leg.origin!.iata_code).join(","),
    to: searchParams.legs.map(leg => leg.destination!.iata_code).join(","),
    adults: searchParams.passengers.adults ? searchParams.passengers.adults : 1,
    type: searchParams.flightType,
    ...(searchParams.passengers.children && searchParams.passengers.children.length > 0
      ? {
          children: searchParams.passengers.children
            .map((child): number => (child === "CHILDREN" || child === "INFANT_ON_SEAT" ? 1 : 0))
            .reduce((total: number, value: number) => total + value)
        }
      : {}),
    ...(searchParams.passengers.children && searchParams.passengers.children.length > 0
      ? {
          infants: searchParams.passengers.children
            .map((child): number => (child === "INFANT_IN_ARMS" ? 1 : 0))
            .reduce((total: number, value: number) => total + value)
        }
      : {}),
    cabin: searchParams.flightClass || NO_VALUE,
    checked_baggage: searchParams.includeBaggage || "false",
    ...(searchParams.passengers.disableds ? { handicapped: searchParams.passengers.disableds } : {}),
    ...(searchParams.passengers.seniors ? { seniors: searchParams.passengers.seniors } : {}),
    currency: searchParams.currency || "",
    ...(filters ? filtersToApiQueryString(filters) : {}),
    ...(pagination && pagination.page ? { page: pagination.page } : {}),
    order_by: orderBy
    //airlines: searchParams.airlines?.length ? searchParams.airlines.join(",") : ""
    //stops: searchParams.stops || NO_VALUE,
  }
  urlParams.stops = urlParams.stops || searchParams.stops || NO_VALUE
  if (searchParams.airlines && searchParams.airlines.length > 0) {
    urlParams.airlines = searchParams.airlines.join(",")
  }
  return urlParams
}

export const departureQueryToMinutes = (timeQS: string): number[] | null => {
  if (!timeQS) return null

  const timesArray = timeQS.split(",")
  const initRange = timesArray[0].split(":")
  const endRange = timesArray[1].split(":")
  const initRangeMinutes = Number(initRange[0]) * 60 + Number(initRange[1])
  const endRangeMinutes = Number(endRange[0]) * 60 + Number(endRange[1]) + 1
  return [initRangeMinutes, endRangeMinutes]
}

export const parseAppliedFilters = (queryString: string): FlightClusterFilters => {
  const mappedQs: any = mapQueryStringToState(queryString)
  return {
    airlines: mappedQs.airlines ? new Set(mappedQs.airlines.split(",")) : new Set(),
    sourceType: mappedQs.sourceType ? new Set(mappedQs.sourcesType.split(",")) : new Set(),
    stops:
      mappedQs.stops && mappedQs.stops !== "NO_VALUE"
        ? new Set(mappedQs.stops.split(",").map((stop: any) => Number(stop)))
        : new Set(),
    outboundDepartureAirports: mappedQs.outbound_departure_airports
      ? new Set(mappedQs.outbound_departure_airports.split(","))
      : new Set(),
    outboundArrivalAirports: mappedQs.outbound_arrival_airports
      ? new Set(mappedQs.outbound_arrival_airports.split(","))
      : new Set(),
    inboundDepartureAirports: mappedQs.inbound_departure_airports
      ? new Set(mappedQs.inbound_departure_airports.split(","))
      : new Set(),
    inboundArrivalAirports: mappedQs.inbound_arrival_airports
      ? new Set(mappedQs.inbound_arrival_airports.split(","))
      : new Set(),
    outboundDepartureTime: departureQueryToMinutes(mappedQs.outbound_departure_time) || DEFAULT_DEPARTURE_TIME,
    inboundDepartureTime: departureQueryToMinutes(mappedQs.inbound_departure_time) || DEFAULT_DEPARTURE_TIME
  }
}

export const parsePagination = (queryString: string): FlightPagination => {
  const mappedQs: any = mapQueryStringToState(queryString)
  return {
    offset: 0,
    limit: mappedQs.page ? CLUSTERS_PER_PAGE * Number(mappedQs.page) : CLUSTERS_PER_PAGE,
    page: mappedQs.page ? Number(mappedQs.page) : 1
  }
}

export const parseOrderBy = (queryString: string): OrderByOptions => {
  const mappedQs: any = mapQueryStringToState(queryString)
  return mappedQs.order_by || "price"
}

export const parseSearchboxParams = (params: string): SearchboxParams => {
  const queryParams = QueryString.parse(params, { ignoreQueryPrefix: true }) as Record<string, string>
  const flightType = (queryParams["type"] || "ROUND_TRIP") as FlightType
  const children: Children[] = []
  _.range(parseInt(queryParams["children"] || "0")).forEach(() => children.push("CHILDREN"))
  _.range(parseInt(queryParams["children_adults"] || "0")).forEach(() => children.push("CHILDREN_ADULT"))
  _.range(parseInt(queryParams["infants"] || "0")).forEach(() => children.push("INFANT_IN_ARMS"))
  _.range(parseInt(queryParams["seat_infants"] || "0")).forEach(() => children.push("INFANT_ON_SEAT"))

  const originList = queryParams["from"] ? queryParams["from"].split(",") : []
  const destinationList = queryParams["to"] ? queryParams["to"].split(",") : []
  const datesList = queryParams["dates"] ? queryParams["dates"].split("|") : []
  const airlines = queryParams["airlines"]?.split(",")

  let legs: Leg[] = []
  for (let index = 0; index < originList.length; index++) {
    const dates = datesList[index].split(",")
    legs.push({
      origin: { id: "", iata_code: originList[index], type: "AIRPORT", name: originList[index] },
      destination: { id: "", iata_code: destinationList[index], type: "AIRPORT", name: destinationList[index] },
      from: moment(dates[0]),
      to: flightType === "ROUND_TRIP" ? moment(dates[1]) : null
    })
  }
  if (legs.length === 0) {
    legs = [{ destination: null, origin: null, from: null, to: null }]
  }

  return {
    flightType: flightType,
    legs: legs,
    passengers: {
      adults: parseInt(queryParams["adults"]) || 1,
      children: children,
      disableds: parseInt(queryParams["handicapped"]) || 0,
      seniors: parseInt(queryParams["seniors"]) || 0
    },
    flightClass: (queryParams["cabin"] || "NO_VALUE") as FlightClass,
    includeBaggage: queryParams["checked_baggage"] && queryParams["checked_baggage"] === "true" ? true : false,
    stops: (queryParams["stops"] || "NO_VALUE") as Stops,
    airlines: airlines,
    currency: queryParams["currency"] || ""
  }
}

export const parseNearbyDatesParams = (params: string, customDates?: string[]) => {
  const query = QueryString.parse(params, { ignoreQueryPrefix: true }) as Record<string, string>

  const flightType = query["type"]
  const from: string = `${query["from"]}${flightType === "ROUND_TRIP" ? `,${query["to"]}` : ""}`
  const to: string = `${query["to"]}${flightType === "ROUND_TRIP" ? `,${query["from"]}` : ""}`
  const dates: string = customDates ? customDates.join(",") : query["dates"]!
  const adults: number | undefined = parseInt(query["adults"])
  const children: number | undefined = parseInt(query["children"])
  const infants: number | undefined = parseInt(query["infants"])
  const seat_infants: number | undefined = parseInt(query["seat_infants"])
  const seniors: number | undefined = parseInt(query["seniors"])
  const disabled: number | undefined = parseInt(query["disabled"])
  const airlines: string = query["airlines"]

  let childrenTotal: number = children ? children : 0
  childrenTotal = childrenTotal + (seat_infants ? seat_infants : 0)

  const passengers = {
    adults: adults ? adults : 0,
    infants: infants ? infants : 0,
    children: childrenTotal,
    seniors: seniors ? seniors : 0,
    disabled: disabled ? disabled : 0
  }

  let departureDates: string[] = []
  let returnDates: string[] = []
  const dateSplitted = dates.split(",")

  const departureDate = moment(dateSplitted[0], DATE_FORMAT)
  const returnDate = moment(dateSplitted[1], DATE_FORMAT)

  for (let i = -2; i < 3; i++) {
    departureDates.push(
      departureDate
        .clone()
        .add(i, "days")
        .format(DATE_FORMAT)
    )
    returnDates.push(
      returnDate
        .clone()
        .add(i, "days")
        .format(DATE_FORMAT)
    )
  }

  let qs: any = {
    departure_dates: departureDates.join(","),
    return_dates: returnDates.join(","),
    from: from,
    to: to,
    ...passengers,
    currency: query["currency"] || ""
  }

  if (airlines) {
    qs = { ...qs, airlines: airlines }
  }

  return [qs, dateSplitted[0], dateSplitted[1]]
}

export const mapFlightsParamsToFlightsApiMatrix = (
  searchParams: SearchboxParams,
  filters: FlightClusterFiltersMatrix
) => {
  return {
    dates: searchParams.legs
      .map(leg =>
        leg.to
          ? leg.from
            ? [leg.from.format("YYYY-MM-DD"), leg.to.format("YYYY-MM-DD")]
            : leg.from
          : leg.from
          ? leg.from.format("YYYY-MM-DD")
          : leg.from
      )
      .join(","),
    from:
      searchParams.flightType === ROUND_TRIP
        ? searchParams.legs[0].origin!.iata_code!.concat(",").concat(searchParams.legs[0].destination!.iata_code!)
        : searchParams.legs.map(leg => leg.origin!.iata_code).join(","),
    to:
      searchParams.flightType === ROUND_TRIP
        ? searchParams.legs[0].destination!.iata_code!.concat(",").concat(searchParams.legs[0].origin!.iata_code!)
        : searchParams.legs.map(leg => leg.destination!.iata_code).join(","),
    adults: searchParams.passengers.adults ? searchParams.passengers.adults : 0,
    ...(searchParams.passengers.children
      ? {
          children: searchParams.passengers.children.reduce(
            (init, child) => init + (child === "CHILDREN" || child === "INFANT_ON_SEAT" ? 1 : 0),
            0
          )
        }
      : {}),
    ...(searchParams.passengers.children
      ? {
          infants: searchParams.passengers.children.reduce(
            (init, child) => init + (child === "INFANT_IN_ARMS" ? 1 : 0),
            0
          )
        }
      : {}),
    ...(searchParams.flightClass && searchParams.flightClass !== NO_VALUE ? { cabin: searchParams.flightClass } : {}),
    ...(searchParams.includeBaggage ? { baggage_type: searchParams.includeBaggage } : {}),
    ...(searchParams.passengers.disableds ? { disabled: searchParams.passengers.disableds } : {}),
    ...(searchParams.passengers.seniors ? { seniors: searchParams.passengers.seniors } : {}),
    //TODO REVISAR OJO
    //...(searchParams.corporateRates ? { corporate_rates: searchParams.corporateRates } : {}),
    ...filtersToApiQueryString(filters),
    offset: filters.offset,
    limit: filters.limit
  }
}
