import { css } from "@emotion/css"
import { Theme } from "@basset-la/themed-components"

export default {
  matrix: (theme: Theme) =>
    css({
      backgroundColor: theme.colors.background_primary,
      " > div": {
        padding: "0"
      },
      "@media(max-width:1024px)": {
        display: "none"
      }
    }),

  container: (t: Theme) =>
    css({
      display: "flex",
      padding: 0,
      flexDirection: "column",
      background: t.colors.background_variant,
      "@media(min-width:1024px)": {
        padding: "24px 0",
        flexDirection: "row",
        width: "100%"
      }
    }),

  leftContainer: css({
    flex: "1 1 auto",
    padding: 0,
    "@media(min-width:1024px)": {
      paddingLeft: "16px",
      width: "352px"
    }
  }),

  rightContainer: css({
    flex: "1 1 auto",
    padding: "0 8px 48px 8px",
    width: "100%",
    "@media(min-width:1024px)": {
      display: "flex",
      flexDirection: "column",
      padding: "0px 16px 0px 16px"
    }
  }),

  filters: css({
    marginTop: "8px"
  }),

  clusterListContainer: css({
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: 0,
    "@media(min-width:1024px)": {
      marginTop: "8px"
    }
  }),

  root: css({
    display: "flex",
    flexDirection: "column"
  }),

  progressStyle: (t: Theme) =>
    css({
      " > div": {
        backgroundColor: `${t.colors.brand_darker} !important`
      }
    }),

  advertising: css({
    marginBottom: "16px"
  }),

  alertContainerStyle: css({
    paddingTop: "8px",
    paddingBottom: "16px"
  }),
  datesMatrixContainer: css({
    padding: "8px"
  }),
  matrixLoaderContainer: css({
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 5
  }),
  datesMatrix: css({
    position: "relative"
  }),
  matrixLoader: (t: Theme) =>
    css({
      "&.MuiCircularProgress-root": {
        color: t.colors.brand_primary
      }
    }),
  matrixLoading: css({
    filter: "blur(3px)"
  }),
  tabs: (theme: Theme) =>
    css({
      "&.MuiTabs-root": {
        minHeight: 0,
        " .MuiTabs-indicator": {
          backgroundColor: theme.colors.brand_primary
        },
        " .MuiTab-root": {
          color: theme.colors.text_primary,
          backgroundColor: theme.colors.background_darker,
          fontFamily: "Roboto",
          fontSize: "12px",
          lineHeight: "1.33",
          fontWeight: 300,
          textTransform: "none",
          padding: "8px 0",
          minHeight: 0
        },
        " .Mui-selected": {
          fontWeight: 500,
          color: theme.colors.brand_primary,
          backgroundColor: theme.colors.background_primary
        }
      }
    })
}
