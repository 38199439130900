import {
  Cluster as ClusterModel,
  FlightsClusterAvailableFilters,
  Price,
  UpSellFare
} from "@basset-la/components-flights/dist/model"
import { fillSelectedBrand } from "@basset-la/components-flights/dist/utils/helpers"
import { FlightResultsModel } from "../FlightResults/model"
import qs from "qs"
import { FetchError, FlightResponseModel } from "../../api/flights"

export interface UpdateUpSellFareModel {
  selectedBrand: number
  price: Price
  upsellFares?: UpSellFare[]
  idx: number
}

export interface ReloadClusterModel {
  idx: number
}

export interface FlightResultsReducerAction {
  type:
    | "set"
    | "append"
    | "error"
    | "hide_alert"
    | "reset_clusters"
    | "reset_all"
    | "update_upsell_fares"
    | "reload_cluster"
  payload?: FlightResponseModel | FetchError | UpdateUpSellFareModel | ReloadClusterModel
}

export const flightResultsReducer = (
  results: FlightResultsModel,
  action: FlightResultsReducerAction
): FlightResultsModel => {
  const checkAlert = (clusters: ClusterModel[]) => {
    var view = false
    const offerPrice = qs.parse(window.location.search.substr(1)).offer_price || ""
    if (clusters.length > 0 && offerPrice) {
      const price = clusters[0].price.total
      const formattedPrice = price.toFixed(2)
      if (offerPrice !== formattedPrice) {
        view = true
      }
    }
    return view
  }

  switch (action.type) {
    case "error":
      return {
        ...results,
        error: action.payload as FetchError
      }

    case "hide_alert":
      return {
        ...results,
        viewAlert: false
      }

    case "reset_clusters":
      return {
        ...results,
        clusters: [],
        selectedBrands: [],
        recommendations: null,
        error: null
      }

    case "reset_all":
      return {
        ...results,
        clusters: [],
        selectedBrands: [],
        recommendations: null,
        matrix: [],
        filters: {} as FlightsClusterAvailableFilters,
        error: null
      }

    case "set":
      const res = action.payload as FlightResponseModel

      const clusters = res && res.clusters ? res.clusters : []
      const viewAlert = checkAlert(clusters)
      return {
        clusters: clusters,
        selectedBrands: clusters.map(c => fillSelectedBrand(c)),
        recommendations: res.recommendations,
        matrix: res && res.matrix ? res.matrix : [],
        filters: res && res.filters ? res.filters : ({} as FlightsClusterAvailableFilters),
        total: res && res.total ? res.total : 0,
        viewAlert: viewAlert,
        error: null
      }

    case "append":
      return {
        ...results,
        clusters: [...results.clusters, ...(action.payload as FlightResponseModel).clusters],
        selectedBrands: [
          ...results.selectedBrands,
          ...(action.payload as FlightResponseModel).clusters.map(c => fillSelectedBrand(c))
        ]
      }

    case "update_upsell_fares":
      const m = action.payload as UpdateUpSellFareModel

      const newClusters = [...results.clusters]
      const newSelectedBrands = [...results.selectedBrands]

      newClusters[m.idx] = {
        ...newClusters[m.idx],
        price: m.price,
        upsell_fares: m.upsellFares
      }
      newSelectedBrands[m.idx] = m.selectedBrand

      return {
        ...results,
        clusters: newClusters,
        selectedBrands: newSelectedBrands
      }

    case "reload_cluster":
      const rcm = action.payload as ReloadClusterModel

      const c = [...results.clusters]
      c[rcm.idx] = { ...results.clusters[rcm.idx] }

      return {
        ...results,
        clusters: c
      }
  }
}
