import { makeApiRequest } from "./config"

export const getAirlineAutocomplete = (language: string) => async (value: string) => {
  if (!value) return
  const uri =
    process.env.REACT_APP_AUTOCOMPLETE_API_URL +
    `?entities=AIRLINE&q=${encodeURIComponent(value)}&language=${language.split("-")[0]}`
  const response = await makeApiRequest(uri, language)
  return response
}
