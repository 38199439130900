import { SearchboxParams } from "@basset-la/components-flights/dist/components/Searchbox/types"
import { mapFlightsParamsToFlightsApi } from "../utils/flights"
import { FlightPagination, NearByDatesResponse } from "../utils/types"
import {
  Cluster as ClusterModel,
  FlightClusterFilters,
  FlightsClusterAvailableFilters
} from "@basset-la/components-flights/dist/model"
import {
  ChangeFareCluster,
  ItineraryRecommendations,
  MatrixModel,
  OrderByOptions
} from "@basset-la/components-flights/dist/model/types"
import { getSessionId } from "../utils/SessionStorage"

export interface Price {
  adults: PassengerFare
  seniors?: PassengerFare
  children?: PassengerFare
  infants?: PassengerFare
  disabled?: PassengerFare
  charges: number
  taxes: number
  total: number
  currency: string
  vat_percentage?: number
  commision_rule_data: Commission
  over_commision_rule_data: Commission
  reseller_charges?: Charge
  agency_charges?: Charge
  agency_cost_effectiveness: number
  detail: object // @deprecated
}

export interface PassengerFare {
  fare: number
  taxes?: ChargeType[]
  quantity: number
}

export interface ChargeType {
  total: number
  type: string
}

export interface Charge {
  operating_cost: number
  fees: number
  markup: number
}

export interface Commission {
  currency: string
  ceded_amount: number
  airline_ceded_amount?: number
  agency_amount?: number
  reseller_amount: number
  airline_amount?: number
}

export interface FlightResponseModel {
  matrix: Array<MatrixModel>
  filters: FlightsClusterAvailableFilters
  clusters: Array<ClusterModel>
  recommendations: ItineraryRecommendations | null
  limit: number
  offset: number
  total: number
}

const createQueryString = (mapQs: any) => new URLSearchParams(mapQs).toString()

export class FetchError extends Error {
  statusCode: number

  constructor(statusCode, message) {
    super(message)
    this.statusCode = statusCode
  }
}

export const fetchFlightClusters = async (
  flightClusterQuery: SearchboxParams,
  filters: FlightClusterFilters,
  pagination: FlightPagination,
  orderBy: OrderByOptions,
  clientId: string,
  site: string,
  channel: string,
  source: string,
  agentId: string | null
): Promise<FlightResponseModel> => {
  const filtersStr = "?".concat(
    createQueryString({
      ...mapFlightsParamsToFlightsApi(flightClusterQuery, filters, pagination, orderBy),
      site: site,
      channel: channel,
      source: source
    })
  )
  const url = process.env.REACT_APP_FLIGHTS_API_URL
    ? `${process.env.REACT_APP_FLIGHTS_API_URL}/clusters${filtersStr}`
    : ""

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY || "",
    "x-client-id": clientId,
    "x-session-id": getSessionId()
  }
  if (agentId) {
    headers["x-user-id"] = agentId
  }

  const results = await fetch(url, {
    method: "GET",
    headers: headers
  })
  const json = await results.json()

  if (!results.ok) {
    throw new FetchError(results.status, json.error)
  }
  return json
}

export const getNearbyDates = async (
  query: any,
  clientID: string,
  agentId: string | null
): Promise<NearByDatesResponse> => {
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY || "",
      "x-client-id": clientID
    }
    if (agentId) {
      headers["x-user-id"] = agentId
    }

    const result = await fetch(
      `${process.env.REACT_APP_FLIGHTS_API_URL}/clusters/near-by-dates?${createQueryString(query)}`,
      {
        method: "GET",
        headers: headers
      }
    )

    if (result.ok) {
      return result.json()
    }
    throw new Error(`failed to get near-by dates. Status code ${result.status}`)
  } catch (e) {
    throw e
  }
}

export const getCluster = async (
  id: string,
  clientId: string,
  site: string,
  agentId: string | null
): Promise<ChangeFareCluster> => {
  const url = `${process.env.REACT_APP_FLIGHTS_API_URL}/clusters/${encodeURI(id)}?site=${site}&channel=WEB&source=`

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_API_KEY || "",
    "x-client-id": clientId
  }
  if (agentId) {
    headers["x-user-id"] = agentId
  }

  const options: any = {
    method: "GET",
    headers: headers
  }

  const response = await fetch(url, options)
  const json = await response.json()

  if (response.ok || response.status === 400) {
    return json as ChangeFareCluster
  }

  throw new FetchError(response.status, json.error)
}
