export const MEDIA_QUERY_BREAKPOINT_MOBILE_MIN = 992
export const MEDIA_QUERY_BREAKPOINT_MOBILE_MAX = 991
export const MEDIA_QUERY_BREAKPOINT_SIMPLIFIED_CLUSTERS_MAX = 1199
export const MEDIA_QUERY_BREAKPOINT_MEDIUM_SCREEN_MAX = 1365

export const LOCATION_TYPE_CITY = "city"
export const LOCATION_TYPE_ACCOMMODATION = "accommodation"
export const LOCATION_TYPE_MULTI_CITY = "multi_city_vicinity"
export const LOCATION_TYPE_NEIGHBORHOOD = "neighborhood"

export const NO_VALUE = "NO_VALUE"
export const ONE_WAY = "ONE_WAY"
export const ROUND_TRIP = "ROUND_TRIP"
export const MULTI_DESTINATION = "MULTI_DESTINATION"
export const FLIGHT_TYPES = [ONE_WAY, ROUND_TRIP, MULTI_DESTINATION]

export const MAXIUM_NIGHTS = 30
export const MINIMUM_NIGHTS = 1
export const MAXIMUM_RANGE_DAYS = 360

export const ANIMATIONS_DURATION = 300
export const SLIDER_ANIMATION_INTERVAL = 6000

export const I18N_NS = "flights-frontend"

export const CLUSTERS_PER_PAGE = 20

export const DATE_FORMAT = "YYYY-MM-DD"

export const CURRENCIES_PER_SITE = {
  AR: ["ARS", "USD"],
  EC: ["USD"],
  CL: ["CLP", "USD"],
  PEN: ["USD", "PEN"],
  US: ["USD"],
  PE: ["USD", "PEN"],
  CO: ["USD", "COP"],
  UY: ["UYU", "USD"]
}
