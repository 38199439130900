import React, { useEffect } from "react"
import ReactGA from "react-ga"
import { BassetProvider } from "@basset-la/themed-components"
import i18n from "../i18n/i18n"
import Routes from "./Routes"
import moment from "moment"
import "moment/locale/es"
import { AuthUserProvider, useConfig } from "@basset-la/components-commons"
import { getUserAgencies } from "../api/auth"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { useAuth0 } from "@auth0/auth0-react"

const AppTheme: React.FC = () => {
  const auth0 = useAuth0()
  const { config } = useConfig()

  useEffect(() => {
    if (i18n.language === "dev") {
      const lang = config!.language && config!.language !== "" ? config!.language : "en"
      i18n.changeLanguage(lang + "-" + config!.country)
    }
    moment.locale(i18n.language !== "dev" ? i18n.language : "en")

    ReactGA.initialize(config!.analytics_id)

    let head = document.getElementsByTagName("head")[0]
    let linkEl = document.createElement("link")
    linkEl.type = "image/x-icon"
    linkEl.rel = "icon"
    // remove existing favicons
    let links = head.getElementsByTagName("link")
    for (let i = links.length; --i >= 0; ) {
      const rel = links[i].getAttribute("rel")
      if (rel && /\bicon\b/i.test(rel)) {
        head.removeChild(links[i])
      }
    }
    linkEl.href = config!.favicon_url

    head.appendChild(linkEl)
  }, [config])

  const theme = {
    colors: {
      brand_darker: config!.brand.colors.darker,
      brand_primary: config!.brand.colors.primary,
      brand_variant: config!.brand.colors.variant,
      text_primary: config!.brand.colors.text_primary,
      text_variant: config!.brand.colors.text_variant,
      background_primary: config!.brand.colors.background_primary,
      background_variant: config!.brand.colors.background_variant,
      background_darker: config!.brand.colors.background_darker,
      text_white: config!.brand.colors.text_white,
      error: config!.brand.colors.error,
      info: config!.brand.colors.info,
      success: config!.brand.colors.success,
      warning: config!.brand.colors.warning,
      searchbox: {
        background: config!.search_box_colors.background,
        text: config!.search_box_colors.text
      }
    },
    logo: config!.brand.logo,
    mobile_logo: config!.brand.mobile_logo,
    brand_colors: {
      background_color: "#FFFFFF",
      click_color: config!.brand.colors.primary,
      highlight_color: config!.search_box_colors.background,
      selection_color: config!.brand.colors.variant
    },
    searchbox_colors: {
      background_color: config!.search_box_colors.text,
      click_color: config!.brand.colors.primary,
      highlight_color: config!.search_box_colors.background,
      selection_color: config!.brand.colors.variant
    }
  }

  return (
    <AuthUserProvider
      auth0={auth0}
      config={config}
      getUserAgencies={getUserAgencies}
      fallback={<AppLoader variant="FLIGHTS" />}
    >
      <BassetProvider theme={theme}>
        <Routes />
      </BassetProvider>
    </AuthUserProvider>
  )
}

export default AppTheme
