import React, { Suspense } from "react"
import AppTheme from "./components/AppTheme"
import AppLoader from "@basset-la/components-commons/dist/components/AppLoader"
import { Auth0Provider } from "@auth0/auth0-react"
import { ConfigProvider } from "@basset-la/components-commons"
import { getAvailableProducts, getClientConfig } from "./api/config"

export default () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE || ""}
  >
    <Suspense fallback={<AppLoader variant="FLIGHTS" />}>
      <ConfigProvider
        getClientConfig={getClientConfig}
        getAvailableProducts={getAvailableProducts}
        fallback={<AppLoader variant="FLIGHTS" />}
      >
        <AppTheme />
      </ConfigProvider>
    </Suspense>
  </Auth0Provider>
)
