import { makeApiRequest } from "./config"

export const getRegions = (language: string) => async (value: string) => {
  if (!value) return []
  const uri =
    process.env.REACT_APP_AUTOCOMPLETE_API_URL +
    `?entities=CITY,AIRPORT&q=${encodeURIComponent(value)}&language=${language.split("-")[0]}&product=FLIGHTS`
  const response = await makeApiRequest(uri, language)
  return response
}
