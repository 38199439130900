import React, { useState, useEffect, useRef } from "react"
import styles from "./FlightResults.styles"
import { useTranslation } from "react-i18next"
import { useTheme } from "@basset-la/themed-components"
import { DATE_FORMAT, I18N_NS } from "../../utils/constants"
import PricesMatrix, { CellInfo } from "@basset-la/components-flights/dist/components/PricesMatrix"
import ClusterList from "./ClusterList"
import FlightsFilters from "@basset-la/components-flights/dist/components/FlightsFilters"
import ErrorMessageWrapper from "./ErrorMessageWrapper"
import Advertising from "@basset-la/components-commons/dist/components/Advertising"
import Alert from "@basset-la/components-commons/dist/components/Alert"
import Text from "@basset-la/components-commons/dist/components/Text"
import {
  FlightClusterFilters,
  FlightType,
  OrderByOptions,
  RecommendedItinerary
} from "@basset-la/components-flights/dist/model"
import { AdvertisingInfo } from "../../utils/types"
import { FlightResultsModel } from "./model"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import { CircularProgress, Tab, Tabs } from "@material-ui/core"
import { ONE_WAY } from "../../utils/constants"
import { MatrixData } from "@basset-la/components-flights"
import moment, { Moment } from "moment"
import { Cluster as ClusterModel } from "@basset-la/components-flights/dist/model"
import DatesMatrix from "@basset-la/components-flights/dist/components/DatesMatrix"
import ItineraryRecommendations from "@basset-la/components-flights/dist/components/ItineraryRecommendations"

export interface Props {
  isMobile: boolean
  isLoading: boolean
  flightType: FlightType
  advertising?: AdvertisingInfo
  appliedFilters: FlightClusterFilters
  orderBy: OrderByOptions
  flightResults: FlightResultsModel
  searchboxComponent: React.ReactNode
  matrixData: MatrixData
  matrixCheapestPrice: number
  loadingNearByDates: boolean
  matrixMostExpensivePrice: number
  matrixDates: string[]
  onPaginatedSearch: () => void
  onClickMatrix: (item: CellInfo) => void
  onFilter: (f: FlightClusterFilters) => void
  onBannerClick: (url: string) => void
  onCloseAlert: () => void
  onBuy: (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => void
  onBuyItinerary: (itinerary: RecommendedItinerary) => void
  onFetchNearbyDates: (customDates?: string[]) => void
  onDateMatrixCellClick: (from: Moment, to: Moment) => void
  onOpenBrandSelectionDialog: (c: ClusterModel, idx: number, selectedOptions: number[], selectedBrand: number) => void
  onChangeOrder: (orderBy: OrderByOptions) => void
}

const FlightResults: React.FC<Props> = ({
  isMobile,
  isLoading,
  flightType,
  advertising,
  flightResults,
  appliedFilters,
  orderBy,
  searchboxComponent,
  matrixData,
  matrixCheapestPrice,
  loadingNearByDates,
  matrixMostExpensivePrice,
  matrixDates,
  onPaginatedSearch,
  onClickMatrix,
  onFilter,
  onBannerClick,
  onCloseAlert,
  onBuy,
  onBuyItinerary,
  onFetchNearbyDates,
  onDateMatrixCellClick,
  onOpenBrandSelectionDialog,
  onChangeOrder
}) => {
  const theme = useTheme()
  const { t } = useTranslation(I18N_NS)

  const containerRef = useRef(null)

  const [containerWidth, setContainerWidth] = useState(0)
  const [tabValue, setTabValue] = useState(0)
  const [matrixDepartureStart, setMatrixDepartureStart] = useState<Moment>(moment())
  const [matrixReturnStart, setMatrixReturnStart] = useState<Moment>(moment())

  useEffect(() => {
    if (containerRef) {
      setContainerWidth(containerRef.current!["clientWidth"] - 32)
    }
  }, [containerRef])

  useEffect(() => {
    setTabValue(0)
  }, [flightResults])

  useEffect(() => {
    setMatrixDepartureStart(moment(matrixDates[0], DATE_FORMAT).subtract(3, "days"))
    setMatrixReturnStart(moment(matrixDates[1], DATE_FORMAT).subtract(3, "days"))
  }, [matrixDates])

  const handlePaginatedSearch = () => {
    onPaginatedSearch()
  }

  const handleClickMatrix = (item: CellInfo) => {
    onClickMatrix(item)
  }

  const handleSubmitFilters = (f: FlightClusterFilters) => {
    onFilter(f)
  }

  const handleBannerClick = (url: string) => {
    onBannerClick(url)
  }

  const handleCloseAlert = () => {
    onCloseAlert()
  }

  const handleBuy = (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => {
    onBuy(clusterIndex, selectedOptions, selectedBrand)
  }

  const onTabChange = (_e: any, newValue: number) => {
    if (newValue === 1) {
      onFetchNearbyDates()
    }
    setTabValue(newValue)
  }

  const handleDatesMatrixWeekChange = (from: Moment, to: Moment) => {
    const fDate = from
    const tDate = to
    if (matrixDepartureStart.isBefore(from)) {
      fDate.add(3, "days")
      tDate.add(3, "days")
    } else {
      fDate.subtract(3, "days")
      tDate.subtract(3, "days")
    }

    if (fDate.isBefore(moment())) {
      return
    }

    const fetchDates = [fDate.format(DATE_FORMAT), tDate.format(DATE_FORMAT)]

    onFetchNearbyDates(fetchDates)

    setMatrixDepartureStart(from.subtract(3, "days"))
    setMatrixReturnStart(to.subtract(3, "days"))
  }

  console.log("recommendations", flightResults.recommendations)

  return (
    <div className={styles.root}>
      <div className={styles.container(theme)}>
        <div className={styles.leftContainer}>
          {searchboxComponent}
          {!isLoading && flightResults.filters && appliedFilters && (
            <div className={styles.filters}>
              <FlightsFilters
                availableFilters={flightResults.filters}
                appliedFilters={appliedFilters}
                onSubmitFilters={handleSubmitFilters}
                flightType={flightType}
              />
            </div>
          )}
        </div>
        <div ref={containerRef} className={styles.rightContainer}>
          {flightResults.error || (flightResults.clusters.length === 0 && !isLoading) ? (
            <ErrorMessageWrapper error={flightResults.error} />
          ) : (
            <>
              {flightResults.clusters.length > 0 && advertising && advertising.top && containerWidth && (
                <div className={styles.advertising}>
                  <Advertising width={`${containerWidth}px`} banner={advertising.top} onClick={handleBannerClick} />
                </div>
              )}
              {!isLoading && flightResults.viewAlert && (
                <div className={styles.alertContainerStyle}>
                  <Alert onClose={handleCloseAlert}>
                    <Text size={14} variant="regular">
                      {t("Results.notAvailableOfferAlert")}
                    </Text>
                  </Alert>
                </div>
              )}
              <div className={styles.matrix(theme)}>
                {flightResults.clusters.length > 0 && (
                  <>
                    <Tabs className={styles.tabs(theme)} variant="fullWidth" value={tabValue} onChange={onTabChange}>
                      <Tab label={t("FlightsResultsComponent.pricesByAirline")}></Tab>
                      {flightType !== ONE_WAY && <Tab label={t("FlightsResultsComponent.nearbyDates")} />}
                    </Tabs>
                    {tabValue === 0 && (
                      <>
                        {flightResults.matrix.length > 0 && (
                          <PricesMatrix variant="WEB" dataSource={flightResults.matrix} onClick={handleClickMatrix} />
                        )}
                      </>
                    )}
                    {tabValue === 1 && (
                      <div className={styles.datesMatrix}>
                        {loadingNearByDates && (
                          <div className={styles.matrixLoaderContainer}>
                            <CircularProgress className={styles.matrixLoader(theme)} />
                          </div>
                        )}
                        <div className={`${styles.datesMatrixContainer} ${loadingNearByDates && styles.matrixLoading}`}>
                          <DatesMatrix
                            variant="WEB"
                            onWeekChange={handleDatesMatrixWeekChange}
                            cellData={matrixData}
                            daysLength={7}
                            mostExpensivePrice={matrixMostExpensivePrice!}
                            cheapestPrice={matrixCheapestPrice!}
                            onCellClick={onDateMatrixCellClick}
                            startFrom={matrixDepartureStart}
                            startTo={matrixReturnStart}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                {flightResults.clusters.length > 0 && advertising && advertising.bottom && containerWidth && (
                  <div className={styles.advertising}>
                    <Advertising
                      width={`${containerWidth}px`}
                      banner={advertising.bottom}
                      onClick={handleBannerClick}
                    />
                  </div>
                )}
                <div className={styles.clusterListContainer}>
                  {flightResults.clusters.length > 0 && (
                    <>
                      <ItineraryRecommendations
                        variant="WEB"
                        recommendations={flightResults.recommendations}
                        orderBy={orderBy}
                        onSelectItinerary={onBuyItinerary}
                        onChangeOrder={onChangeOrder}
                      />
                      <ClusterList
                        isLoading={false}
                        onSelectItinerary={handleBuy}
                        dataSource={flightResults.clusters}
                        selectedBrands={flightResults.selectedBrands}
                        onPaginatedSearch={handlePaginatedSearch}
                        onOpenBrandSelectionDialog={onOpenBrandSelectionDialog}
                      />
                    </>
                  )}
                  {isLoading && <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="FLIGHTS_RESULT" />}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default FlightResults
