import React, { useEffect, useReducer, useState } from "react"

import moment, { Moment } from "moment"
import { stringify } from "query-string"
import ReactGA from "react-ga"
import { useTranslation } from "react-i18next"
import { useHistory, useLocation } from "react-router"

import { MatrixData } from "@basset-la/components-flights"
import { CellInfo } from "@basset-la/components-flights/dist/components/PricesMatrix"
import { SearchboxParams } from "@basset-la/components-flights/dist/components/Searchbox/types"
import {
  Cluster as ClusterModel,
  FlightClusterFilters,
  FlightsClusterAvailableFilters,
  OrderByOptions,
  Price,
  RecommendedItinerary
} from "@basset-la/components-flights/dist/model"

import { getAdvertising } from "../../api/advertising"
import { getAirlineAutocomplete } from "../../api/autocomplete"
import { FetchError, fetchFlightClusters, getCluster, getNearbyDates } from "../../api/flights"
import { getRegions } from "../../api/geo"
import {
  CLUSTERS_PER_PAGE,
  DATE_FORMAT,
  I18N_NS,
  NO_VALUE,
  ROUND_TRIP,
  CURRENCIES_PER_SITE,
  MAXIMUM_RANGE_DAYS
} from "../../utils/constants"
import {
  mapFlightsParamsToUrl,
  parseAppliedFilters,
  parseNearbyDatesParams,
  parseOrderBy,
  parsePagination,
  parseSearchboxParams
} from "../../utils/flights"
import { AdvertisingInfo, FlightPagination, NearByDatesResponse } from "../../utils/types"
import FlightResults from "../FlightResults/FlightResults"
import Wrapper from "../Wrapper"
import { flightResultsReducer } from "./reducer"
import { useAuthUser, useConfig } from "@basset-la/components-commons"
import ChangedFareDialog from "@basset-la/components-flights/dist/components/ChangedFareDialog"
import ClusterBrands from "@basset-la/components-flights/dist/components/ClusterBrands"
import Searchbox from "@basset-la/components-flights/dist/components/Searchbox"
import { useMediaQuery } from "@material-ui/core"
import LinearProgress from "@basset-la/components-commons/dist/components/LinearProgress"
import Message from "@basset-la/components-commons/dist/components/Message"
import { fillSelectedBrand } from "@basset-la/components-flights/dist/utils/helpers"
import ItineraryNotFoundDialog from "@basset-la/components-flights/dist/components/ItineraryNotFoundDialog"

interface BuyConfirmation {
  confirm: boolean
  data: {
    itineraryId: string
    selectedBrand: number
  } | null
}

interface ChangedFareData {
  flow: "brands" | "buy"
  oldPrice: Price
  newPrice: Price
}

interface SelectBrandData {
  idx: number
  cluster: ClusterModel
  selectedOptions: number[]
  selectedBrand: number
}

const Results: React.FC<{}> = () => {
  const { t, i18n } = useTranslation(I18N_NS)
  const { search } = useLocation()
  const history = useHistory()
  const { config } = useConfig()
  const { userId } = useAuthUser()
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const params = parseSearchboxParams(search)
  const [advertising, setAdvertising] = useState<AdvertisingInfo>()
  const [searchParams, setSearchParams] = useState<SearchboxParams>(params)
  const [appliedFilters, setAppliedFilters] = useState<FlightClusterFilters>(parseAppliedFilters(search))
  const [pagination, setPagination] = useState<FlightPagination>(parsePagination(search))
  const [orderBy, setOrderBy] = useState<OrderByOptions>(parseOrderBy(search))
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [lastRequestedPage, setLastRequestedPage] = useState(1)
  const [matrixData, setMatrixData] = useState<MatrixData>({})
  const [loadingNearByDates, setLoadingNearByDates] = useState(false)
  const [matrixCheapestPrice, setMatrixCheapestPrice] = useState(0)
  const [matrixMostExpensivePrice, setMatrixMostExpensivePrice] = useState(0)
  const [matrixDates, setMatrixDates] = useState(["", ""])
  const [buyConfirmation, setBuyConfirmation] = useState<BuyConfirmation>({ confirm: false, data: null })
  const [openChangeFareDialog, setOpenChangeFareDialog] = useState(false)
  const [changedFareData, setChangedFareData] = useState<ChangedFareData | null>(null)
  const [isLoadingCluster, setIsLoadingCluster] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedBrandData, setSelectedBrandData] = useState<SelectBrandData | null>(null)
  const [openBrandDialog, setOpenBrandDialog] = useState(false)
  const [openItineraryNotFoundDialog, setOpenItineraryNotFoundDialog] = useState(false)

  const [flightResults, dispatchFlightResults] = useReducer(flightResultsReducer, {
    clusters: [],
    selectedBrands: [],
    recommendations: null,
    matrix: [],
    filters: {} as FlightsClusterAvailableFilters,
    total: 0,
    viewAlert: false,
    error: null
  })

  useEffect(() => {
    document.title = t("Results.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [t])

  useEffect(() => {
    const loadAdvertising = async () => {
      try {
        const adv = await getAdvertising(config!)
        setAdvertising(adv)
      } catch (e) {
        console.log(e)
      }
    }

    loadAdvertising()
  }, [config])

  useEffect(() => {
    const getClusters = async () => {
      const results = await fetchFlightClusters(
        searchParams,
        appliedFilters,
        pagination,
        orderBy,
        config!.agency_id,
        config!.country,
        "WEB",
        config!.source,
        userId
      )
      return results
    }

    const loadClusters = async () => {
      try {
        const results = await getClusters()
        setIsLoading(false)
        if (pagination.page === 1) {
          dispatchFlightResults({ type: "set", payload: results })
        } else {
          dispatchFlightResults({ type: "append", payload: results })
        }
      } catch (e) {
        console.error(e)
        dispatchFlightResults({ type: "error", payload: e as FetchError })
      }
    }

    loadClusters()
  }, [config, pagination, searchParams, appliedFilters, orderBy, userId])

  const pushUrl = (
    sp: SearchboxParams,
    f: FlightClusterFilters,
    p: FlightPagination,
    o: OrderByOptions,
    reset: boolean = false
  ) => {
    const queryParams = stringify(mapFlightsParamsToUrl(sp, o, f, p), { encode: false })
    history.push({
      pathname: "/flights/results",
      search: queryParams
    })

    setSearchParams(sp)
    setAppliedFilters(f)
    setPagination(p)
    setOrderBy(o)
    setLastRequestedPage(1)
    setMatrixData({})
    if (reset) {
      dispatchFlightResults({ type: "reset_all" })
    } else {
      dispatchFlightResults({ type: "reset_clusters" })
    }
    setIsLoading(true)
  }

  const handleSearch = (sp: SearchboxParams) => {
    const f: FlightClusterFilters = {
      airlines: sp.airlines ? new Set(sp.airlines) : new Set(),
      sourceType: new Set(),
      stops: sp.stops !== undefined && sp.stops !== NO_VALUE ? new Set([sp.stops]) : new Set(),
      outboundDepartureAirports: new Set(),
      outboundArrivalAirports: new Set(),
      inboundDepartureAirports: new Set(),
      inboundArrivalAirports: new Set(),
      outboundDepartureTime: [],
      inboundDepartureTime: []
    }
    const p: FlightPagination = {
      offset: 0,
      limit: CLUSTERS_PER_PAGE,
      page: 1
    }
    pushUrl({ ...sp }, { ...f }, { ...p }, orderBy, true)
  }

  const handleClickMatrix = (item: CellInfo) => {
    const f: FlightClusterFilters = {
      airlines: item.airline !== undefined ? new Set([item.airline]) : new Set(),
      sourceType: new Set(),
      stops: item.stops !== undefined ? new Set([`${item.stops}`]) : new Set(),
      outboundDepartureAirports: new Set(),
      outboundArrivalAirports: new Set(),
      inboundDepartureAirports: new Set(),
      inboundArrivalAirports: new Set(),
      outboundDepartureTime: [],
      inboundDepartureTime: []
    }
    pushUrl(searchParams, f, pagination, orderBy)
  }

  const handleSubmitFilters = (f: FlightClusterFilters) => {
    const p: FlightPagination = {
      offset: 0,
      limit: CLUSTERS_PER_PAGE,
      page: 1
    }
    pushUrl(searchParams, { ...f }, { ...p }, orderBy)
  }

  const handlePaginatedSearch = () => {
    const { offset, limit, page } = pagination as FlightPagination
    if (!flightResults.total || flightResults.total > Number(offset) + Number(limit)) {
      const currentPage = page ? page + 1 : Number(offset) / CLUSTERS_PER_PAGE + 1
      if (currentPage > lastRequestedPage) {
        const p: FlightPagination = {
          offset: offset + (limit && limit > CLUSTERS_PER_PAGE ? limit : CLUSTERS_PER_PAGE),
          limit: CLUSTERS_PER_PAGE,
          page: currentPage
        }

        setLastRequestedPage(currentPage)
        setPagination(p)
        setIsLoading(true)
      }
    }
  }

  const getItineraryID = (cluster: ClusterModel, selectedOptions: number[]): string => {
    let ids: string[] = []
    for (const i in cluster.segments) {
      ids.push(cluster.segments[i].options[selectedOptions[i]].id)
    }
    return ids.join("_")
  }

  useEffect(() => {
    if (!buyConfirmation.confirm || !buyConfirmation.data) return

    const checkoutPath = `/checkout/flights/${buyConfirmation.data.itineraryId}?selected_brand=${buyConfirmation.data.selectedBrand}`
    window.location.href = checkoutPath
  }, [buyConfirmation])

  const handleAcceptNewFare = () => {
    switch (changedFareData!.flow) {
      case "buy":
        setBuyConfirmation({
          ...buyConfirmation,
          confirm: true
        })
        break
      case "brands":
        setOpenBrandDialog(true)
        break
    }
    setOpenChangeFareDialog(false)
    setChangedFareData(null)
  }

  const handleRejectNewFare = () => {
    if (changedFareData!.flow === "brands") {
      dispatchFlightResults({ type: "reload_cluster", payload: { idx: selectedBrandData!.idx } })
      setSelectedBrandData(null)
    }

    setOpenChangeFareDialog(false)
    setChangedFareData(null)
  }

  const checkAndBuyItinerary = async (originalClusterPrice: Price, itineraryId: string, selectedBrand: number) => {
    try {
      setIsLoadingCluster(true)
      setErrorMessage("")

      const itinerary = await getCluster(itineraryId, config!.agency_id, config!.country, userId)

      const newPrice = itinerary.upsell_fares ? itinerary.upsell_fares[selectedBrand].price : itinerary.price
      if (Math.round(originalClusterPrice.total) - Math.round(newPrice.total) === 0) {
        setBuyConfirmation({
          confirm: true,
          data: {
            itineraryId: itineraryId,
            selectedBrand: selectedBrand
          }
        })
        return
      }

      setBuyConfirmation({
        confirm: false,
        data: {
          itineraryId: itineraryId,
          selectedBrand: selectedBrand
        }
      })
      setChangedFareData({
        flow: "buy",
        oldPrice: originalClusterPrice,
        newPrice: newPrice
      })
      setOpenChangeFareDialog(true)
      setIsLoadingCluster(false)
    } catch (err) {
      console.log(err)
      setIsLoadingCluster(false)
      if ((err as FetchError).statusCode === 404) {
        setOpenItineraryNotFoundDialog(true)
      } else {
        setErrorMessage(t("Results.messages.fetchItineraryError"))
      }
    }
  }

  const handleBuyItinerary = (itinerary: RecommendedItinerary) => {
    checkAndBuyItinerary(itinerary.price, itinerary.id, 0)
  }

  const handleBuy = (clusterIndex: number, selectedOptions: number[], selectedBrand: number) => {
    const cluster = { ...flightResults.clusters[clusterIndex] }
    const originalPrice = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price

    const itineraryId = getItineraryID(cluster, selectedOptions)

    checkAndBuyItinerary(originalPrice, itineraryId, selectedBrand)
  }

  const handleBannerClick = (url: string) => {
    window.open(url, "_blank")?.focus()
  }

  const handleCloseAlert = () => {
    dispatchFlightResults({ type: "hide_alert" })
  }

  const fetchNearbyDates = async (customDates?: string[]) => {
    const [qs, dDate, rDate] = parseNearbyDatesParams(search, customDates)
    const query = {
      ...qs,
      site: config.country,
      channel: "WEB"
    }

    try {
      setLoadingNearByDates(true)
      const nearbyDates = await getNearbyDates(query, config.agency_id, userId!)

      processNearbyResponse(nearbyDates)
      setMatrixDates([dDate, rDate])
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingNearByDates(false)
    }
  }

  const processNearbyResponse = (resp: NearByDatesResponse) => {
    const departureDate = searchParams.legs[0].from!.format(DATE_FORMAT)
    const returnDate = searchParams.legs[0].to!.format(DATE_FORMAT)
    let data: MatrixData = matrixData ? { ...matrixData } : {}
    if (!data) {
      data = {}
    }

    let highestTotal = 0
    let lowestTotal = 0

    for (let date of resp) {
      const selected = departureDate === date.departure_date && returnDate === date.return_date

      if (!selected && (date.price.total > highestTotal || highestTotal === 0)) {
        highestTotal = date.price.total
      }

      if (!selected && (date.price.total < lowestTotal || lowestTotal === 0)) {
        lowestTotal = date.price.total
      }

      if (data[date.departure_date] === undefined) {
        data[date.departure_date] = {}
      }

      data[date.departure_date][date.return_date] = {
        price: {
          value: date.price.total,
          currency: date.price.currency
        },
        airlines: date.airlines,
        type: selected ? "SELECTED" : undefined
      }
    }

    setMatrixData({ ...data })
    setMatrixCheapestPrice(lowestTotal)
  }

  const onDateMatrixCellClick = async (from: Moment, to: Moment) => {
    let data: MatrixData = matrixData ? { ...matrixData } : {}

    const departureDate = from.format(DATE_FORMAT)
    const returnDate = to.format(DATE_FORMAT)

    const sp = { ...searchParams }
    sp.legs[0].from = from
    sp.legs[0].to = to

    if (data[departureDate] && data[departureDate][returnDate]) {
      if (matrixData![departureDate][returnDate]!.type !== "LOADING") {
        pushUrl({ ...sp }, appliedFilters, pagination, orderBy, true)
      }
      return
    }

    if (data[departureDate] === undefined) {
      data[departureDate] = {}
    }
    data[departureDate][returnDate] = {
      type: "LOADING"
    }
    setMatrixData(data)

    try {
      const result = await fetchFlightClusters(
        sp,
        {
          airlines: new Set(),
          sourceType: new Set(),
          stops: new Set(),
          outboundDepartureAirports: new Set(),
          outboundArrivalAirports: new Set(),
          inboundDepartureAirports: new Set(),
          inboundArrivalAirports: new Set(),
          inboundDepartureTime: [],
          outboundDepartureTime: []
        },
        {
          ...pagination,
          offset: 0,
          page: 0
        },
        orderBy,
        config.agency_id,
        config.country,
        "WEB",
        "",
        userId
      )

      if (result && result.clusters && result.clusters.length) {
        const cluster = result.clusters[0]
        const total: number = cluster.price.total
        let newCheapest = matrixCheapestPrice
        let newMostExpensive = matrixMostExpensivePrice

        if (matrixMostExpensivePrice && total > matrixMostExpensivePrice) {
          newMostExpensive = total
        } else {
          if (matrixCheapestPrice && total < matrixCheapestPrice) {
            newCheapest = total
          }
        }

        data![departureDate][returnDate] = {
          price: {
            value: cluster.price.total,
            currency: cluster.price.currency
          }
        }

        setMatrixMostExpensivePrice(newMostExpensive)
        setMatrixCheapestPrice(newCheapest)
      } else {
        data![departureDate][returnDate] = {
          type: "NO_RESULT"
        }
      }
    } catch (e) {
      console.error(e)
      data![departureDate][returnDate] = undefined
    } finally {
      setMatrixData({ ...data })
    }
  }

  const handleCloseMessage = () => {
    setErrorMessage("")
  }

  const handleOpenBrandSelectionDialog = (
    cluster: ClusterModel,
    idx: number,
    selectedOptions: number[],
    selectedBrand: number
  ) => {
    const loadCluster = async () => {
      try {
        setIsLoadingCluster(true)
        setErrorMessage("")

        const opts = cluster.segments.map(_ => 0)
        const itineraryId = getItineraryID(cluster, opts)
        const itinerary = await getCluster(itineraryId, config!.agency_id, config!.country, userId)

        if (!itinerary.upsell_fares) {
          setErrorMessage(t("Results.messages.brandsNotFoundError"))
          return
        }

        let itinerarySelectedBrand = selectedBrand
        if (!cluster.upsell_fares) {
          /*
          const clusterBrandName = cluster.segments[0].options[0].legs
            .map(l => l.brand?.name || "")
            .reduce((prev, curr) => {
              return prev !== "" ? prev : curr
            }, "")
          */
          itinerarySelectedBrand = fillSelectedBrand(itinerary)
        }

        const itineraryPrice = itinerary.upsell_fares
          ? itinerary.upsell_fares[itinerarySelectedBrand].price
          : itinerary.price
        setSelectedBrandData({
          idx,
          selectedOptions,
          selectedBrand: itinerarySelectedBrand,
          cluster: {
            ...cluster,
            price: itineraryPrice,
            upsell_fares: itinerary.upsell_fares
          }
        })

        const clusterPrice = cluster.upsell_fares ? cluster.upsell_fares[selectedBrand].price : cluster.price
        if (clusterPrice.total - itineraryPrice.total === 0) {
          setOpenBrandDialog(true)
        } else {
          setChangedFareData({
            flow: "brands",
            oldPrice: clusterPrice,
            newPrice: itineraryPrice
          })
          setOpenChangeFareDialog(true)
        }
      } catch (err) {
        console.error(err)
        if ((err as FetchError).statusCode === 404) {
          dispatchFlightResults({ type: "reload_cluster", payload: { idx } })
          setOpenItineraryNotFoundDialog(true)
        } else {
          setErrorMessage(t("Results.messages.fetchItineraryError"))
        }
      } finally {
        setIsLoadingCluster(false)
      }
    }

    loadCluster()
  }

  const handleCloseBrandSelectionDialog = () => {
    dispatchFlightResults({ type: "reload_cluster", payload: { idx: selectedBrandData!.idx } })

    setSelectedBrandData(null)
    setOpenBrandDialog(false)
  }

  const handleSelectNewBrand = (brandIdx: number) => {
    dispatchFlightResults({
      type: "update_upsell_fares",
      payload: {
        idx: selectedBrandData!.idx,
        price: selectedBrandData!.cluster.price,
        upsellFares: selectedBrandData!.cluster.upsell_fares,
        selectedBrand: brandIdx
      }
    })

    setSelectedBrandData(null)
    setOpenBrandDialog(false)
  }

  const handleRefreshItineraries = () => {
    setOpenItineraryNotFoundDialog(false)
    handleSearch(searchParams)
  }

  const handleCloseItineraryNotFoundDialog = () => {
    setOpenItineraryNotFoundDialog(false)
  }

  const handleChangeOrder = (order: OrderByOptions) => {
    pushUrl(searchParams, appliedFilters, pagination, order, true)
  }

  const flightType = searchParams && searchParams.flightType ? searchParams.flightType : ROUND_TRIP

  const currencies = CURRENCIES_PER_SITE[config.country as keyof typeof CURRENCIES_PER_SITE] || []

  const searchboxComponent = (
    <Searchbox
      getRegions={getRegions(i18n.language)}
      getAirlines={getAirlineAutocomplete(i18n.language)}
      config={{
        minDate: moment().add(config!.search_configuration.min_days, "days"),
        maxDate: moment().add(config!.search_configuration.max_days, "days"),
        maximumNights: config!.search_configuration.range_days || MAXIMUM_RANGE_DAYS,
        maxSegments: 4,
        maxPassengers: 9,
        allowCurrency: config!.search_configuration.allow_currency,
        disallowMultipleDestinationsSearch: config!.search_configuration.disallow_multiple_destinations_search
      }}
      params={searchParams}
      onSearch={handleSearch}
      currencies={currencies}
    />
  )

  return (
    <Wrapper>
      {isLoadingCluster && <LinearProgress />}
      <FlightResults
        isMobile={isMobile}
        isLoading={isLoading}
        flightType={flightType}
        advertising={advertising}
        appliedFilters={appliedFilters}
        orderBy={orderBy}
        flightResults={flightResults}
        searchboxComponent={searchboxComponent}
        matrixData={matrixData}
        matrixCheapestPrice={matrixCheapestPrice}
        loadingNearByDates={loadingNearByDates}
        matrixMostExpensivePrice={matrixMostExpensivePrice}
        matrixDates={matrixDates}
        onPaginatedSearch={handlePaginatedSearch}
        onClickMatrix={handleClickMatrix}
        onFilter={handleSubmitFilters}
        onBannerClick={handleBannerClick}
        onCloseAlert={handleCloseAlert}
        onBuy={handleBuy}
        onBuyItinerary={handleBuyItinerary}
        onFetchNearbyDates={fetchNearbyDates}
        onDateMatrixCellClick={onDateMatrixCellClick}
        onOpenBrandSelectionDialog={handleOpenBrandSelectionDialog}
        onChangeOrder={handleChangeOrder}
      />
      {changedFareData && (
        <ChangedFareDialog
          variant="WEB"
          open={openChangeFareDialog}
          isMobile={isMobile}
          closeOnlyUserAction={true}
          currency={changedFareData.newPrice.currency}
          newPrice={changedFareData.newPrice.total}
          oldPrice={changedFareData.oldPrice.total}
          handleAcceptNewFare={handleAcceptNewFare}
          handleRejectNewFare={handleRejectNewFare}
        />
      )}
      {selectedBrandData && (
        <ClusterBrands
          variant="WEB"
          openDialog={openBrandDialog}
          cluster={selectedBrandData!.cluster}
          selectedOptions={selectedBrandData!.selectedOptions}
          selectedBrand={selectedBrandData!.selectedBrand}
          onChange={handleSelectNewBrand}
          onClose={handleCloseBrandSelectionDialog}
        />
      )}
      {openItineraryNotFoundDialog && (
        <ItineraryNotFoundDialog
          open={openItineraryNotFoundDialog}
          onClose={handleCloseItineraryNotFoundDialog}
          onSearch={handleRefreshItineraries}
        />
      )}
      <Message
        open={errorMessage !== ""}
        variant="snackbar"
        action="error"
        message={errorMessage}
        onClose={handleCloseMessage}
      />
    </Wrapper>
  )
}

export default Results
