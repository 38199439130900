import React, { useEffect } from "react"
import { stringify } from "query-string"
import ReactGA from "react-ga"
import { useHistory } from "react-router"
import Searchbox, { DefaultSearchParams } from "@basset-la/components-flights/dist/components/Searchbox"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { getAirlineAutocomplete } from "../../api/autocomplete"
import { getRegions } from "../../api/geo"
import { I18N_NS, CURRENCIES_PER_SITE, MAXIMUM_RANGE_DAYS } from "../../utils/constants"
import { mapFlightsParamsToUrl } from "../../utils/flights"
import Wrapper from "../Wrapper"
import styles from "./Home.styles"
import { useConfig } from "@basset-la/components-commons"

const Home: React.FC = () => {
  const { i18n, t } = useTranslation(I18N_NS)
  const history = useHistory()
  const { config } = useConfig()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    document.title = t("Home.documentTitle")
  }, [t])

  const handleSubmitSearch = (params: any) => {
    const queryParams = stringify(mapFlightsParamsToUrl(params, "price"), { encode: false })
    history.push({
      pathname: "/flights/results",
      search: queryParams
    })
  }

  const homeImageUrl = config!.home_image_url || "https://statics.basset.la/flights/home.jpg"

  const currencies = CURRENCIES_PER_SITE[config.country as keyof typeof CURRENCIES_PER_SITE] || []

  return (
    <Wrapper>
      <div className={styles.container(homeImageUrl)}>
        <Searchbox
          getRegions={getRegions(i18n.language)}
          getAirlines={getAirlineAutocomplete(i18n.language)}
          config={{
            minDate: moment().add(config!.search_configuration.min_days, "days"),
            maxDate: moment().add(config!.search_configuration.max_days, "days"),
            maximumNights: config!.search_configuration.range_days || MAXIMUM_RANGE_DAYS,
            maxSegments: 4,
            maxPassengers: 9,
            allowCurrency: config!.search_configuration.allow_currency,
            disallowMultipleDestinationsSearch: config!.search_configuration.disallow_multiple_destinations_search
          }}
          wide={true}
          params={DefaultSearchParams}
          onSearch={handleSubmitSearch}
          currencies={currencies}
        />
      </div>
    </Wrapper>
  )
}

export default Home
